.App {
  height: 100%;
  width: 100%;
}

.nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px; /* Space below the nav */
  padding: 30px;
}

.header h1 {
  margin: 0;
  font-size: 2rem;
}

.main {
  margin-top: 20px; /* Space between header and main */
}

.main p {
  font-size: 1.2rem;
  margin-bottom: 20px; /* Space above the button */
}

button {
  background-color: white;
  color: #282c34;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
}

button:hover {
  background-color: #f5f5f5;
}